import type { Session } from "@ory/client";

export default defineNuxtRouteMiddleware(
  async (to, _) => {
    // If we're running tests, skip the authentication check.
    if (process.env.NODE_ENV === "test") {
      return true;
    }
    const sessionCookie = useCookie("ory_kratos_session");
    const { $ory } = useNuxtApp();
    const config = useRuntimeConfig();

    const { session, setSession } = $ory;
    // Attempt to validate the session by making a request to the Kratos endpoint.
    // Note: Depending on your setup, you might need to adjust the fetch URL to
    // the full path of your Kratos service if it's not hosted on the same domain.
    if (session.value !== null) {
      if (session.value.expires_at && new Date(session.value.expires_at).getTime() < new Date().getTime()) {
        return navigateTo("/login?return_to=" + to.path);
      }
      return true;
    }
    try {
      const newSession = await $fetch<Session>(`${config.public.kratosPublicUrl}/sessions/whoami`, {
        // Include credentials to ensure cookies are sent with the request.
        // This is especially important for server-side rendering.
        headers: { cookie: `ory_kratos_session=${sessionCookie.value};` }
      });
      setSession(newSession);
    } catch {
      // console.debug("No session found");
      return navigateTo("/login?return_to=" + to.path);
    }
    // console.debug("Found session");
    return true;
  }
  // If the session is valid, continue with the request.
);
